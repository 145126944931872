var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "2 0 14 14" } },
    [
      _c("title", [_vm._v("Invoicing")]),
      _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
        _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M15.22,4.69v8.62H2.78V4.69H15.22m.13-.78H2.65A.65.65,0,0,0,2,\n        4.56v8.88a.65.65,0,0,0,.65.65h12.7a.65.65,0,0,0,.65-.65V4.56a.65.65,0,0,0-.65-.65Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M13.81,7.06H10.17a.39.39,0,0,1-.39-.39.38.38,0,0,1,.39-.39h3.64a.39.39,0,0,1,.39.39A.4.4,0,0,1,13.81,7.06Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M13.81,9.39H10.17A.38.38,0,0,1,9.78,9a.39.39,0,0,1,.39-.39h3.64A.4.4,0,0,1,14.2,9,.39.39,0,0,1,13.81,9.39Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M13.81,11.72H10.17a.38.38,0,0,1-.39-.39.39.39,0,0,1,.39-.39h3.64a.4.4,0,0,1,.39.39A.39.39,0,0,1,13.81,\n        11.72Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M5.72,12v-.73a3,3,0,0,1-1.31-.33l.23-.9a2.68,2.68,0,0,0,1.28.34c.43,0,.73-.17.73-.47s-.24-.48-.81-.67C5,\n        9,4.46,8.58,4.46,7.83A1.41,1.41,0,0,1,5.75,6.47V5.74h.76v.67a2.58,2.58,0,0,1,1.11.26l-.22.86a2.66,2.66,0,0,\n        0-1.11-.26c-.5,0-.66.22-.66.43s.27.42.92.66c.92.32,1.28.75,1.28,1.44a1.47,1.47,0,0,1-1.36,1.42V12Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }